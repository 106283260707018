import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Skill Practice: Wall Walks & Turkish Get Ups`}</p>
    <p>{`then,`}</p>
    <p>{`30:00 amrap of:`}</p>
    <p>{`3-Wall Walks`}</p>
    <p>{`9-GHD Situps`}</p>
    <p>{`18-KBS’s (53/35)`}</p>
    <p>{`27-Calorie Assault Bike`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`The 6:00pm class is cancelled tonight so we can set up for
tomorrow’s Granite Games Spring Throwdown.  If you can help us set up
please show up at 6:00pm!  Your help is greatly appreciated.`}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`Saturday is the Granite Games competition.  There are no classes or
open gym on Saturday but we encourage you to come out and cheer on the
teams!`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      